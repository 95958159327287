@import './boostrap-overrides';
@import './page-template';


/* Home.
-------------------------------------------------- */
.full-screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.application-section {
  background-image:
    linear-gradient(to top left, rgba(88, 144, 167, 0.8), rgba(3, 65, 84, 0.8)),
    url('https://cdn.aws.apriori.com/images/clouds.png');
  background-size: cover;
  min-height: 24rem;
  max-height: 48rem;
  padding: 0;
  justify-content: center;
}

.info-section {
  display: flex;
  padding: 40px 15px;
}


/* Initialization.
-------------------------------------------------- */
.message {
  font-size: 1.95rem !important;
}


/* Switch Modals.
-------------------------------------------------- */
.switch-customer-modal {

  .modal-body {
    overflow-y: visible !important;

    .apriori-dropdown {

      .dropdown-toggle {
        background-color: $white;
        border-color: $gray-400;
        color: $gray-800;

        &:active, &:focus, &:hover {
          background-color: $gray-100;
          border-color: $gray-400;
          box-shadow: none;
          color: $gray-800;
        }

        &:focus {
          background-color: $gray-200;
        }
      }

      button {
        font-size: 1rem;
      }
    }
  }

  .modal-footer {

    .action-buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .btn {
      font-size: 1rem;
    }
  }
}
