@import './page-template.scss';


/* Forms.
 ------------------------------------------------- */
label {
  font-size: 1rem !important;
}

.custom-control-label {
  padding-top: 3px;
}


/* Modals.
 ------------------------------------------------- */
.modal {
  display: flex;
  margin: 3rem auto !important;
  max-height: calc(100vh - 6rem);
  position: relative;

  .modal-content {
    height: inherit;

    .modal-header {
      align-items: center;
      border-bottom: none;
      background-color: lighten($teal, 15%);
      display: flex;

      .modal-title {
        color: $white;
        font-weight: 400;
      }

      .close {
        align-items: center;
        border-radius: 100%;
        color: $white;
        display: flex;
        font-size: 1.75rem;
        font-weight: 400;
        justify-content: center;
        height: 30px;
        margin: 0;
        outline: none;
        opacity: 1;
        padding: 5px;
        text-shadow: none;
        width: 30px;

        &:hover {
          background-color: rgba(1, 1, 1, 0.1);
          opacity: 1;
        }

        span {
          height: 28px;
        }
      }
    }

    .modal-body {
      overflow-y: auto;
    }
  }

  .cancel-disabled > button {
    visibility: hidden;
  }
}
