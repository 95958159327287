//
// page-template.scss
//
// Styles in this file should only affect the global layout
// and presentation of any given aPriori Web App.
//


@import '@aprioritechnologies/core/scss/variables';

html {
  font-size: 12px;
}

body {
  background-color: $gray-100;
  font-family: 'Arial', sans-serif !important;
  min-width: $body-min-width;

  #root {
    display: flex;
    flex-flow: column;
    min-height: 100vh;

    main {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      overflow-x: hidden;
      width: 100%;
    }
  }
}
